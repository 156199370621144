import React from 'react'
import Layout from '../components/layout'
import { Row, Col } from 'reactstrap';

const ObtGruppe = (props) => {
  const images = require.context('../images/obtgruppe/', true);
  let imgOBT = images('./OBT_Logo_RGB_Pos.svg');
  let imgBaker = images('./BakerTillySwitzerland_RGB_Black.svg');
  let imgBudliger = images('./BT_Logo-RGB-Tiefblau.svg');
  let imgFigas = images('./FIGAS Logo 2011_ CYMK.svg');
  let imgAstal = images('./Logo ASTAL_AKTUELL.png');
  /*let imgRevidas = images('./Logo revidas.svg');*/
  

  return (
    <Layout>
      <section className="section-wrapper">
        <header className="section-header">
          <h3 name="title">LEHMANN+PARTNER Informatik AG Teil der OBT Gruppe</h3>
        </header>

        <span name="body">
          <p>
            Seit Januar 2023 ist LEHMANN+PARTNER Informatik AG Teil der OBT Gruppe. Die LEHMANN+PARTNER Informatik AG mit Sitz in Luzern arbeitet bereits seit vielen Jahren eng mit dem Informatikteam der OBT AG zusammen. Beide Firmen haben das gleiche Verständnis und Engagement, Kunden in ihrem Digitalisierungsprozess zu unterstützen. Dies hat die Inhaber der LEHMANN+PARTNER Informatik AG, Martin Lehmann und Annette Lehmann-Lehmann, dazu bewogen, ihr Unternehmen im Sinne einer vorausschauenden Nachfolgeplanung an die OBT Gruppe zu verkaufen.
          </p>
         
        </span>

        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <div className="news-post" key="div_1">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img src={imgOBT} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                      <h3>OBT AG</h3>
                        
                        <p>Die OBT AG gehört in der Schweiz zu den sechs grössten Un­ter­nehmen im Be­reich Treuhand, Wirt­schafts­prüfung und Be­ratung. Der Kun­den­fokus liegt bei den KMU wobei sie auch bör­sen­ko­tierte Un­ter­nehmen, öf­fentlich-recht­liche Kör­per­schaften, Stif­tungen und Ver­eine kom­petent und per­sönlich berät.
                        </p>
                        <p>
                          OBT verfügt in allen Kern­kom­pe­tenzen über er­fah­rene und aus­ge­wie­sene Spe­zia­listen. Dabei sind sie in­ter­dis­zi­pli­näres Ar­beiten ge­wohnt. Ganz egal, ob Sie Dienst­lei­stungen aus einem oder aus meh­reren Be­reichen be­nö­tigen – bei OBT er­halten Sie in jedem Fall eine hoch­pro­fes­sio­nelle Be­treuung und die in­di­vi­duell besten Lö­sungen.
                        </p>
                        <a href="https://www.obt.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>


            <div className="news-post" key="div_2">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img src={imgBaker} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h3>Baker Tilly OBT AG</h3>
                        
                        <p>
                          Die Toch­ter­ge­sell­schaft Baker Tilly OBT AG fo­kus­siert sich auf die Be­treuung von Un­ter­nehmen mit in­ter­na­tio­nalem Business. Als un­ab­hän­giges Mit­glied von Baker Tilly In­ter­na­tional, einer welt­weiten Or­ga­ni­sa­tion von un­ab­hän­gigen Re­vi­sions- und Be­ra­tungs­firmen in 133 Ländern, kann die Baker Tilly OBT AG auf ein um­fas­sendes Netzwerk zu­greifen.
                          </p>  <p>
                          Die Baker Tilly OBT AG be­treut in­ter­na­tio­nale Un­ter­nehmen unter dem Label Baker Tilly Swit­z­erland.

                        </p>
                        <a href="https://www.bakertilly.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>


            <div className="news-post" key="div_3">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img src={imgBudliger} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h3>Budliger Treuhand AG</h3>
                        
                        <p>
                        Seit der Gründung 1942 ori­en­tiert sich Bud­liger Treuhand AG am Leitsatz «Ihnen dienen zu dürfen». Die Bud­liger ist Dienst­leister aus Über­zeugung, die die Wünsche der Kunden immer an den Anfang des Han­delns stellt.
                        </p>  <p>

Die Partner und die Ge­schäfts­leitung sind aus­ge­wie­sene Ex­perten in Wirt­schafts­prüfung, Rech­nungs­legung und Con­trolling, Steu­er­wesen und Per­so­nal­wesen. Ihnen zur Seite stehen hoch qua­li­fi­zierte Mit­ar­bei­tende mit lang­jäh­riger Be­rufs­er­fahrung und un­ter­neh­me­ri­schem Know-how. Die Kunden er­halten damit eine um­fas­sende und auf ihre Be­dürf­nisse aus­ge­rich­tete Be­treuung. Die An­sprech­person ist für die Kunden da, wann immer sie diese brauchen.
</p>  <p>

Pri­vat­per­sonen im In- und Ausland und Un­ter­nehmen aller Grössen und Branchen zählen auf die Kom­pe­tenzen in der Steu­er­be­ratung, in der Wirt­schafts­prüfung und Wirt­schafts­be­ratung, im Rech­nungs- und Per­so­nal­wesen und in der erbrecht­lichen Be­ratung der Bud­liger. Bud­liger ist für Ihre Kunden da!

                        </p>
                        <a href="https://www.budliger.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>

            <div className="news-post" key="4">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img src={imgFigas} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h3>FIGAS Autogewerbe-Treuhand der Schweiz AG</h3>
                        
                        <p>
                        Seit 1952 hat sich die FIGAS Au­to­ge­werbe-Treuhand der Schweiz AG auf das Au­to­mo­bil­ge­werbe fo­kus­siert und pro­funde Bran­chen­kennt­nisse er­ar­beitet. Diese er­mög­lichen ihr, auf das Kun­den­be­dürfnis aus­ge­rich­tete Dienst­lei­stungen in den Be­reichen Treuhand, Wirt­schafts­prüfung, Un­ter­neh­mens­be­ratung und Business-Ma­nagement an­zu­bieten.
                        </p>  <p>
Zu ihren Kunden zählen Ga­ra­gen­be­triebe sowie bran­chen­nahe Un­ter­nehmen, aber auch Im­port­ge­sell­schaften, die einen neu­tralen, sach­kun­digen und der Dis­kre­tion ver­pflich­teten Partner schätzen.
</p>  <p>
Per 1. Ok­tober 2010 übernahm die OBT AG zu 100% die Ak­tien der FIGAS Au­to­ge­werbe-Treuhand der Schweiz AG und der FIGAS Re­vi­sion AG. Seit 2012 sind die OBT Stand­orte Brugg, Lachen, Rap­perswil, Schaff­hausen, Schwyz, St.Gallen, Wein­felden, Zürich und seit dem 1. Ja­nuar 2015 Riaz­zino auch gleich­zeitig FIGAS-Stütz­punkte mit bran­chen­spe­zi­fisch aus­ge­bil­deten Treu­hand­spe­zia­listen.
                        </p>
                        <a href="https://www.figas.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>

            {/*<div className="news-post" key="div_5">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img src={imgRevidas} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h3>Revidas Treuhand AG</h3>
                        
                        <p>
                        Wir sind ein Un­ter­nehmen, welches seit mehr als 30 Jahren im Fi­nanz­be­reich tätig ist. Unser pro­fes­sio­nelles Team steht Ihnen tat­kräftig zur Seite und berät Sie gerne.
                        </p>
                        <a href="https://www.revidas.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>*/}

            <div className="news-post" key="div_6">
                <Row className="news-post-row">
                    <Col className="img-obtgruppe" xs="0" sm="0" md="4"><img style={{ display: 'block', width: '100%' }} src={imgAstal} /></Col>
                    <Col className="post-text" xs="12" sm="12" md="8">
                        <h3>ASTAL Treuhand & Verwaltungs AG</h3>
                        
                        <p>
                        Die ASTAL Treuhand & Ver­wal­tungs AG wurde 1997 ge­gründet und gehört seit dem 1. Ja­nuar 2010 zur OBT Gruppe.
                        </p>  <p>
Die Be­wirt­schaftung von Lie­gen­schaften ist die Haupt­tä­tigkeit von ASTAL. Alle treu­hän­de­ri­schen Dienst­lei­stungen werden über die OBT AG ab­ge­wickelt. Die Im­mo­bi­li­en­kunden er­halten so um­fas­sende Be­ra­tungs­dienst­lei­stungen aus einer Hand.
</p>  <p>
Lie­gen­schafts­be­wirt­schaftung mit Mehrwert! Für Pri­vat­per­sonen und Un­ter­nehmen über­nimmt ASTAL die Be­wirt­schaftung von Wohn- und Ge­schäfts­lie­gen­schaften. Die qua­li­fi­zierten Spe­zia­listen un­ter­stützen die Kunden mit Be­ratung bei der Eva­lua­tion sowie beim Kauf und Verkauf von Im­mo­bi­lien. Mit lang­jäh­rigem Know-how werden sie kom­petent, per­sönlich und mit vollem En­ga­gement im Steuer- und Fi­nanz­be­reich, bei Er­b­an­ge­le­gen­heiten und bei Fi­nan­zie­rungs­fragen ihrer Im­mo­bilie be­raten.
                        </p>
                        <a href="https://www.astal.ch" target="_blank" rel="noopener noreferrer">Website</a>
                    </Col>
                </Row>
            </div>

        </div>

        
      </section>
      
      
    </Layout>
  )
}

export default ObtGruppe

